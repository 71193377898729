<app-overlay [loading]="loading" appFloatingElement (close)="onClose()">
  <div class="overlay__buttons">
    <app-button theme="transparent" class="back-button" (click)="onClose()">
      {{ 'action.back' | translate }}
    </app-button>
    <app-button class="next-button" [disabled]="!!userPaymentForm?.invalid" (click)="saveUserPayment()">
      {{ 'action.save' | translate }}
    </app-button>
  </div>

  <div class="overlay__header">
    <h2>
      {{ 'payment.edit_user_payment_for' | translate }}
      {{ userPayment?.user?.first_name }} {{ userPayment?.user?.last_name }}
    </h2>
    <p class="sub-header ns-text small">{{ userPayment?.payment?.name }}</p>
  </div>

  @if (userPayment) {
    <app-fade-in class="overlay__body overlay__body--default-width">
      @if (userPayment.is_mandatory) {
        @if (userPayment.paid_status === PaidStatus.Declined) {
          <app-info-block type="warning" [title]="'economy.payment_is_mandatory_for_joining_the_group_2' | translate">
            {{
              'economy.payment_is_mandatory_for_joining_the_group_description_2'
                | translate
                  : {
                      name: this.userPayment.user.first_name + ' ' + this.userPayment.user.last_name,
                      group: this.userPayment.payment.group_name
                    }
            }}
          </app-info-block>
        } @else {
          <app-info-block type="warning" [title]="'economy.payment_is_mandatory_for_joining_the_group' | translate">
            <p>
              {{
                'economy.payment_is_mandatory_for_joining_the_group_description'
                  | translate
                    : {
                        name: this.userPayment.user.first_name + ' ' + this.userPayment.user.last_name,
                        group: this.userPayment.payment.group_name
                      }
              }}
            </p>
            <p>{{ 'economy.payment_is_mandatory_for_joining_the_group_description_3' | translate }}</p>
            <p>{{ 'economy.payment_is_mandatory_for_joining_the_group_description_4' | translate }}</p>
          </app-info-block>
        }
      }

      <form [formGroup]="userPaymentForm" class="ns-form ns-light">
        @if (userPayment.payment.payment_type !== PaymentTypes.EventPayment) {
          @if (!userPayment.on_hold) {
            <mat-form-field>
              <mat-label>{{ 'payment.publish_date' | translate }}</mat-label>
              <input matInput type="date" formControlName="publishDate" />
              <mat-icon matPrefix>calendar_today</mat-icon>
              <mat-hint>{{ 'payment_details.publish_date_hint' | translate }}</mat-hint>

              @if (userPaymentForm.controls.publishDate!.hasError('minDate')) {
                <mat-error>{{ 'payment_details.publish_date_error' | translate }}</mat-error>
              }
            </mat-form-field>
          }

          <mat-form-field>
            <mat-label>{{ 'economy.payment_deadline' | translate }}</mat-label>
            <input matInput type="date" formControlName="dueDate" />
            <mat-icon matPrefix>calendar_today</mat-icon>
          </mat-form-field>
        }

        @if (userPayment.payment.payment_type === PaymentTypes.MembershipFee) {
          <div class="ns-membership-fee-price">
            <p class="ns-price-categories-header">
              <span class="ns-subtitle">{{ 'economy.price_options' | translate }}</span>
              @if (isLoadingPriceCategories) {
                <app-loader [static]="true" [size]="20"></app-loader>
              }
            </p>

            @if (priceCategories) {
              <app-expand-y class="ns-price-categories-wrapper">
                <div class="ns-price-categories-description">
                  @if (priceCategories.length === 1) {
                    <p class="ns-text small">
                      {{
                        'payment.user_payment_price_categories_description'
                          | translate
                            : { firstName: this.userPayment.user.first_name, lastName: this.userPayment.user.last_name }
                      }}
                    </p>
                  } @else {
                    <p class="ns-text small">
                      {{
                        'payment.user_payment_price_categories_description_2'
                          | translate
                            : { firstName: this.userPayment.user.first_name, lastName: this.userPayment.user.last_name }
                      }}
                    </p>
                  }
                  <p class="ns-text small">{{ 'economy.nif_minimum_price_description' | translate }}</p>
                </div>

                <div
                  class="ns-price-categories"
                  [class.ns-disabled]="isSettingCustomPrice || userPaymentForm.value.status !== PaidStatus.NotPaid"
                >
                  @for (category of priceCategories; track category.id) {
                    <div class="ns-price-category">
                      <div class="ns-category-name-wrapper ns-subtitle">
                        <span class="ns-category-name">{{ category.name }}</span>
                        <span class="ns-price">{{ category.gross_amount | currency }}</span>
                      </div>
                      <div class="ns-category-details">
                        <div class="ns-category-restrictions">
                          @if ((category.min_age | isNumber) && (category.max_age | isNumber)) {
                            @if (category.min_age === category.max_age) {
                              <p>{{ 'economy.for_age' | translate: { age: category.min_age } }}</p>
                            } @else {
                              <p>
                                {{
                                  'economy.from_age_to_age'
                                    | translate: { ageFrom: category.min_age, ageTo: category.max_age }
                                }}
                              </p>
                            }
                          } @else if ((category.min_age | isNumber) && !(category.max_age | isNumber)) {
                            @if (category.min_age === minAge) {
                              {{ 'economy.all_ages' | translate }}
                            } @else {
                              {{ 'economy.above_age' | translate: { age: category.min_age - 1 } }}
                            }
                          } @else if (!(category.min_age | isNumber) && (category.max_age | isNumber)) {
                            @if (category.max_age === maxAge) {
                              {{ 'economy.all_ages' | translate }}
                            } @else {
                              {{ 'economy.under_age' | translate: { age: category.max_age + 1 } }}
                            }
                          }

                          @if (category.allowed_user_type | isNumber) {
                            @switch (category.allowed_user_type) {
                              @case (EconomyUserType.Admins) {
                                <p>{{ 'economy.for_group_type' | translate }} {{ 'user_types.admins' | translate }}</p>
                              }

                              @case (EconomyUserType.Players) {
                                <p>{{ 'economy.for_group_type' | translate }} {{ 'user_types.players' | translate }}</p>
                              }

                              @case (EconomyUserType.AssociateMembers) {
                                <p>
                                  {{ 'economy.for_group_type' | translate }}
                                  {{ 'user_types.associate_members' | translate }}
                                </p>
                              }
                            }
                          }
                        </div>

                        <div class="ns-category-price-description">
                          <p
                            [innerHTML]="
                              'economy.including_transaction_fee' | translate: { fee: category.total_fee | currency }
                            "
                          ></p>
                          <p>
                            {{ 'economy.net_amount' | translate }}: <span>{{ category.net_amount | currency }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  }
                </div>

                <p
                  class="ns-divider ns-subtitle"
                  [class.ns-disabled]="userPaymentForm.value.status !== PaidStatus.NotPaid"
                >
                  {{ 'miscellaneous.or' | translate }}
                </p>

                @if (isSettingCustomPrice) {
                  <div
                    class="ns-custom-price-header"
                    [class.ns-disabled]="userPaymentForm.value.status !== PaidStatus.NotPaid"
                  >
                    <span class="ns-subtitle">{{ 'payment.custom_price' | translate }}</span>
                    <button
                      class="ns-close-custom-price"
                      mat-icon-button
                      [disabled]="userPaymentForm.value.status !== PaidStatus.NotPaid"
                      (click)="toggleCustomPrice(false)"
                    >
                      <mat-icon>clear</mat-icon>
                    </button>
                  </div>
                  <div class="ns-field-with-switcher">
                    <mat-form-field class="ns-no-gap">
                      @if (userPaymentForm.controls.includeFee!.value) {
                        <mat-label>{{ 'economy.price' | translate }}</mat-label>
                      } @else {
                        <mat-label>{{ 'economy.net_amount' | translate }}</mat-label>
                      }

                      <input
                        matInput
                        type="number"
                        formControlName="customPrice"
                        autocomplete="off"
                        appDisableValueChangeOnWheel
                      />
                    </mat-form-field>

                    <mat-slide-toggle formControlName="includeFee" class="ns-switcher-field">
                      <p class="bold">{{ 'miscellaneous.include_transaction_fee' | translate }}</p>
                    </mat-slide-toggle>
                  </div>

                  <app-price
                    class="ns-membership-fee-amount"
                    [class.ns-disabled]="userPaymentForm.value.status !== PaidStatus.NotPaid"
                    [amount]="userPaymentForm.controls.customPrice!.value"
                    [includeTransactionFee]="userPaymentForm.controls.includeFee!.value"
                    [initialValues]="
                      customPriceCategory && {
                        netAmount: customPriceCategory.net_amount,
                        transactionFee: customPriceCategory.total_fee,
                        totalAmount: customPriceCategory.gross_amount
                      }
                    "
                  ></app-price>
                } @else {
                  <app-button
                    theme="dark"
                    class="ns-set-custom-price"
                    [disabled]="userPaymentForm.value.status !== PaidStatus.NotPaid"
                    (click)="toggleCustomPrice(true)"
                  >
                    + {{ 'payment.set_custom_price' | translate }}
                  </app-button>
                }
              </app-expand-y>
            }
          </div>
        }

        @if (
          userPayment.payment.payment_type === PaymentTypes.TrainingFee ||
          userPayment.payment.payment_type === PaymentTypes.RegularPayment
        ) {
          <div class="ns-field-with-switcher">
            <mat-form-field class="ns-no-gap">
              @if (userPaymentForm.controls.includeFee!.value) {
                <mat-label>{{ 'economy.price' | translate }}</mat-label>
              } @else {
                <mat-label>{{ 'economy.net_amount' | translate }}</mat-label>
              }
              <input matInput type="number" formControlName="amount" autocomplete="off" appDisableValueChangeOnWheel />
            </mat-form-field>

            <mat-slide-toggle formControlName="includeFee" class="ns-switcher-field">
              <p class="bold">{{ 'miscellaneous.include_transaction_fee' | translate }}</p>
            </mat-slide-toggle>
          </div>

          <app-price
            [amount]="userPaymentForm.value.amount!"
            [includeTransactionFee]="userPaymentForm.value.includeFee!"
            [initialValues]="{
              netAmount: userPayment.net_amount,
              transactionFee: userPayment.total_fee,
              totalAmount: userPayment.gross_amount
            }"
          ></app-price>
        }

        <mat-form-field>
          <mat-label>{{ 'table.payment_status' | translate }}</mat-label>
          <mat-select formControlName="status">
            <mat-option [value]="PaidStatus.NotPaid">{{ 'payment.not_paid' | translate }}</mat-option>

            @if (userPayment.is_optional || userPayment.paid_status === PaidStatus.Declined) {
              <mat-option [value]="PaidStatus.Declined">{{ 'payment.declined' | translate }}</mat-option>
            }

            <mat-option [value]="PaidStatus.Exempted">{{ 'payment.exempted' | translate }}</mat-option>
            <mat-option [value]="PaidStatus.PaidManually">{{ 'payment.paid_manually' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>

        @if (userPaymentForm.value.status === PaidStatus.PaidManually) {
          <app-expand-y class="ns-expandable-field-wrapper">
            <mat-form-field class="ns-expandable-field ns-no-gap">
              <mat-label>{{ 'economy.payment_date' | translate }}</mat-label>
              <input matInput type="date" formControlName="chargeDate" [max]="$any(today)" />
              <mat-icon matPrefix>calendar_today</mat-icon>
            </mat-form-field>
          </app-expand-y>
        }

        <mat-form-field>
          <mat-label>{{ 'economy.payment_note' | translate }}</mat-label>
          <textarea
            matInput
            formControlName="note"
            [rows]="2"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
          ></textarea>
        </mat-form-field>
      </form>
    </app-fade-in>
  }
</app-overlay>
